export default {
  siteTitle: 'Get bonus',
  blogerName: 'VINI SANTOS',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/canal_vinisantos'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@ViniSantos_Slots'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Vini_SantosPG'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/vinisantos_slots'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/cab56e070',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/ce1364125',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c5bee57d6',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cd544220c',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c7d284dcb',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c302ae6d0',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c16c746da',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cad973963',
      gameTitle: 'Jet Air',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c9511b7fb',
      gameTitle: 'LEGZO PUNK',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>VINISANTOS</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'VINISANTOS',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
